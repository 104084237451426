<template>
    <div>
    <ul :id="'company-node-'+parent" >
        <li v-for="person in getNodes(nodes,'people')" :id="'person-'+person.hashid" v-if="person.hashid != parent">
          <b-card class="relative mb-0 company-dept">
            <div class="emp-pic">
              <b-img :src="person.avatar" class="pro-img" fluid rounded="circle" alt="" @click="viewEmployee = person ; showEmpInfoSidebar = true;"></b-img>
            </div>
            <div class="view-count d-flex align-items-center justify-content-between">
              <!-- <b-badge variant="light" class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon person-circle icon="people" scale="1" class="mr-50"></b-icon> {{person['employees_count']}}</b-badge>
              <b-badge variant="light" class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon person-circle icon="building" scale="1" class="mr-50"></b-icon> {{person['departments_count']}}</b-badge> -->
            </div>
            <div class="tree-content">
              <div class="mb-0 mt-1">
                <div class="goal-header text-center mt-2">
                  <h5 class="mb-0 emp-name font-weight-bolder" v-b-tooltip.hover.v-default :title="person.name">{{person.name}}</h5>
                  <h6 class="description mt-25 small">{{person.designation}}</h6>
                </div>
              </div>
            </div>
            <!-- <div class="expand-team">
              <div class="expand-content">
                <span class="team-count mx-25 small"><b-icon stacked icon="person"/>193</span>
     <li class="hr-info">
                        <div class="company-dept">
                          <b-button class="hracc-details">HR & Accounts <b-badge>7</b-badge></b-button>
                        </div>      -     <div style="border-left: 1px solid #dee1e4">
                  <span class="team-count mr-50 ml-25 small"><b-icon stacked icon="layers" class="mr-25"/>15</span>
                  <b-icon stacked icon="chevron-right" rotate="90" class="ml-25"></b-icon>
                </div>
              </div>
            </div> -->
            <b-button v-if="person.teammates && person.teammates.length" :id="'arrow-icon-'+person.hashid" class="expand-icon"><feather-icon icon="ChevronDownIcon" size="18" class="text-primary font-weight-bolder m-0" @click="listClicked(person.hashid);"/></b-button>
          </b-card>
          <nodes v-if="person.teammates &&  person.teammates.length" :parent="person.hashid" :parentData="person" :ref="'children-'+person.hashid"/>
        </li>
        <!-- <li class="hr-info" v-for="department in getNodes(nodes,'department')" >
          <div class="company-dept">
            <b-button class="hracc-details">{{department[0]}} <b-badge>{{department[1].length}}</b-badge></b-button>
          </div>
          <ul>
            <li class="sub-main" v-for="employee in department[1]" :id="'person-'+employee.hashid">
              <b-card class="relative mb-0 company-dept">
                <div class="emp-pic">
                  <b-img :src="employee.avatar" class="w-100 pro-img" fluid rounded="circle" alt="profile photo"></b-img>
                </div>
                 <div class="tree-content">
                  <div class="mb-0 my-1">
                    <div class="goal-header text-center mt-2">
                      <h5 class="mb-0 emp-name font-weight-bolder" v-b-tooltip.hover.v-default :title="employee.name">{{employee.name}}</h5>
                      <h6 class="description mt-25 small">{{employee.designation}}</h6>
                    </div>
                  </div>
                </div>
                <div class="view-count d-flex align-items-center justify-content-between">
                  <b-badge variant="light" class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon person-circle icon="people" scale="1" class="mr-50"></b-icon> {{employee['employees_count']}}</b-badge>
                  <b-badge variant="light" class="total-employee text-secondary small d-flex align-items-center justify-content-center"><b-icon person-circle icon="building" scale="1" class="mr-50"></b-icon> {{employee['departments_count']}}</b-badge>
                    </div>
                    <b-button v-if="employee.teammates && employee.teammates.length" :id="'arrow-icon-'+employee.hashid" class="expand-icon"><feather-icon icon="ChevronDownIcon" size="18" class="text-primary font-weight-bolder m-0" @click="listClicked(employee.hashid);"/></b-button>
              </b-card>

              <nodes v-if="employee.teammates && employee.teammates.length" :parent="employee.hashid" :parentData="employee" :ref="'children-'+employee.hashid"/>
            </li>
          </ul>
        </li> -->
      </ul>
      <b-sidebar 
      :id="parent+'-profile-view-popover'"
      :visible="showEmpInfoSidebar"
      v-if="viewEmployee"
      :ref="parent+'-profile-view-popover'" bg-variant="white" shadow  no-header right width="35%"
        title="Employee Profile">
        <template>
          <!-- Header -->
          <div class="
                d-flex
                justify-content-between
                align-items-center
                content-sidebar-header
                px-2
                py-2
                border-bottom
                sticky-top
              ">
            <h3 class="mb-0 font-weight-bold">Employee Profile</h3>
            <div>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat-danger" class="btn-icon rounded-circle"
                @click="showEmpInfoSidebar = false">
                <feather-icon size="20" icon="XCircleIcon" />
              </b-button>
            </div>
          </div>
          <!-- BODY -->

          <b-card
            :img-src="require('@/assets/images/banner/banner-'+viewEmployee.banner_id+'.jpg')"
            img-alt="Profile Cover Photo"
            img-top
            class="card-profile sidebar-pop"
          >
            <div class="profile-image-wrapper ">
              <div class="profile-image p-0">
                <b-avatar
                  size="114"
                  variant="light"
                  :src="viewEmployee.avatar"
                />
              </div>
            </div>
            <h3>{{viewEmployee.name}}</h3>
            <h6 class="text-muted">
              {{viewEmployee.email}}
            </h6>
            <b-badge
              class="profile-badge"
              variant="light-primary"
            >
            {{viewEmployee.designation}}
            </b-badge>
            <hr class="mb-2">

            <!-- follower projects rank -->
              <div>
                <b>Employee Details</b>
                <b-list-group class="mt-1">
                  <b-list-group-item >
                    <b-row >
                      <b-col cols="4" >
                        Bio : 
                      </b-col>

                      <b-col cols="8">
                        {{ viewEmployee.bio }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Date of Join :
                      </b-col>

                      <b-col cols="8">
                        {{ viewEmployee.date_of_join }}
                      </b-col>
                    </b-row> 
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Employee ID : 
                      </b-col>

                      <b-col cols="8">
                        {{ viewEmployee.employee_id }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Reporting Manager : 
                      </b-col>

                      <b-col cols="8">
                        {{ viewEmployee.manager }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Zircly Role : 
                      </b-col>

                      <b-col cols="8">
                        {{ viewEmployee.role }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Division : 
                      </b-col>

                      <b-col cols="8">
                        {{ viewEmployee.division }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Department : 
                      </b-col>

                      <b-col cols="8">
                        {{ viewEmployee.department }}
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                  <b-list-group-item>
                    <b-row >
                      <b-col cols="4">
                        Shift : 
                      </b-col>

                      <b-col cols="8">
                        {{ viewEmployee.shift }}
                        <span>
                          ({{ viewEmployee.shift_details.session_1_start_from | formatTime }} -
                          {{ viewEmployee.shift_details.session_2_end_at | formatTime }})
                        </span>
                      </b-col>
                    </b-row>
                  </b-list-group-item>
                </b-list-group>
              </div>
          </b-card>
        </template>
      </b-sidebar>
    </div>
</template>
<script>
import {
    BAvatar,
    BCard,
    BCardText,
    BImg,
    BLink,
    BRow,
    BCol,
    BAvatarGroup,
    VBTooltip,
    BFormTextarea,
    BButton,
    BFormGroup,
    BEmbed,
    BCarousel,
    BCarouselSlide,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import QuillInput from "@/components/QuillInput.vue";

import $ from "jquery";
import Vue from "vue";
import { bus } from "@/main.js";
import axios from "@axios";
import VueCookies from "vue-cookies";
  import Nodes from "./nodes.vue"
Vue.use(VueCookies);

export default {
  name:"nodes",
    components: {
    BAvatar,
    BCard,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
    QuillInput,
    // Navbar Components
    BCarousel,
    BCarouselSlide,
    Nodes
    },
    directives: {
    "b-tooltip": VBTooltip,
    Ripple,
    },
    props:{
        parent:{
          required: true,
        },
        parentData:{
          required: true,
        }
    },
    data() {
    return {
      nodes:[],
      showEmpInfoSidebar:false,
      viewEmployee:null
    }
    },
    mounted() {
      var nodeId = '#company-node-'+this.parent;
      $(nodeId).hide();
      $(nodeId+'.active').hide();  
    },
    methods: {
      getSubNodesPeople(){
        axios
        .get("companyTree/subNodes/"+this.parent)
        .then((response) => {
          if (response.data.success) {
            this.nodes = response.data.data;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Unable to fetch Company Tree",
                icon: "EditIcon",
                variant: "danger",
                text: response.data.message,
              },
            });
          }
        });
      },
      getSubNodesPeople1(){
        axios
        .get("companyTree/subNodes/"+this.parent)
        .then((response) => {
          if (response.data.success) {
            this.nodes = response.data.data;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Unable to fetch Company Tree",
                icon: "EditIcon",
                variant: "danger",
                text: response.data.message,
              },
            });
          }
        });
      },
      listClicked(id){
        var nodeId = '#person-'+id;
        $("#arrow-icon-"+id).toggleClass("activenode");
        $(nodeId).addClass("open-tree"); 
        $(nodeId).siblings('li').removeClass('open-tree');
        $(nodeId).siblings('li').find('nodes').hide();
        var mydiv = $(nodeId);
        window.scrollTo({
          top: mydiv.prop("scrollHeight"),
          behavior: "smooth"
        });
        var children = $(nodeId).find('>div> ul');
        if (children.is(":visible")) {
          children.hide('fast').removeClass('active');
        }
        else {
          children.show('fast').addClass('active');
          this.$refs['children-'+id][0].getSubNodesPeople();
        }

      },
      getNodes(records,type){
        // if(this.parent != 'company' && this.parentData && records){
        //   var filteredNodes = [];
        //   var filteredDepartments = [];
        //   records.forEach(node => {
        //     if(node.hashid != this.parentData.hashid){
              
        //       if(node.division == this.parentData.division){
        //         filteredNodes.push(node);
        //       }else{
        //         if(filteredDepartments[node.division]){
        //           filteredDepartments[node.division].push(node);
        //         }else{
        //           filteredDepartments[node.division] = [node];
        //         }
        //       }
        //     }
            
        //   });
        //   if(type == 'people'){
        //     return filteredNodes;
        //   }else{
        //     return Object.entries(filteredDepartments);
        //   }
        // }
        if(type == 'people'){
            return records;
          }else{
            return [];
          }

      }
    },
}
</script>